import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../utils/SectionProps";
import SectionHeader from "./SectionHeader";
import Image from "./Image";
import { f, database } from "../config/firebaseConfig";
import { useHistory } from "react-router";
import Bounce from "react-activity/lib/Bounce";

const propTypes = {
    ...SectionTilesProps.types,
};

const defaultProps = {
    ...SectionTilesProps.defaults,
};

function Team(props) {
    const [projects, setProjects] = useState({});
    useEffect(() => {
        database
            .ref("projects/")
            .once("value", (snapshot) => {
                if (snapshot.exists()) {
                    setProjects(snapshot.val());
                } else {
                    alert("No projects available");
                }
            })
            .catch((error) => {
                alert(error);
            });
    }, []);

    const [pubs, setPubs] = useState({});
    useEffect(() => {
        database
            .ref("publications/")
            .once("value", (snapshot) => {
                if (snapshot.exists()) {
                    setPubs(snapshot.val());
                } else {
                    alert("No publications or presentations available")
                }
            })
    }, []);


    const {
        className,
        topOuterDivider,
        bottomOuterDivider,
        topDivider,
        bottomDivider,
        hasBgColor,
        invertColor,
        pushLeft,
    } = props;

    const history = useHistory();
    function goTo(page, project) {
        history.push({
            pathname: page,
            state: { project: project },
        });
    }
    const outerClasses = classNames(
        "team section center-content",
        topOuterDivider && "has-top-divider",
        bottomOuterDivider && "has-bottom-divider",
        hasBgColor && "has-bg-color",
        invertColor && "invert-color",
        className
    );

    const innerClasses = classNames(
        "team-inner section-inner",
        topDivider && "has-top-divider",
        bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    function Languages() {
        const langs = [
            {thumbnail:"https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/resources%2FSQL.png?alt=media&token=54f64aee-a9f6-45bb-984f-0fe85633a82d",
                link: "https://github.com/MalakSadek?tab=repositories&q=sql",},
            {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/resources%2Fc.png?alt=media&token=b37b75b0-5e9d-4f6f-8d36-388f70238922",
                link: "https://github.com/MalakSadek?tab=repositories&language=c%2B%2B",},
            {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/resources%2Fjava.png?alt=media&token=8b7e6399-3e62-4f7e-9e08-4055d87087fb",
                link: "https://github.com/MalakSadek?tab=repositories&language=java",},
            {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/resources%2Fkotlin.png?alt=media&token=1d5826b1-5c12-454d-8b56-52d83051af80",
                link: "https://github.com/MalakSadek?tab=repositories&q=android",},
            {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/resources%2Fphp.png?alt=media&token=a5b40551-f536-437a-95c0-d00759969763",
                link: "https://github.com/MalakSadek?tab=repositories&q=php",},
            {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/resources%2Fpython.png?alt=media&token=562b2e12-304d-41da-9c76-a15b0d663d02",
                link: "https://github.com/MalakSadek?tab=repositories&q=python",},
            {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/resources%2Fswift.png?alt=media&token=e381dd54-9e38-496b-b14c-3e7a22a65fce",
                link: "https://github.com/MalakSadek?tab=repositories&q=swift",},
            {thumbnail: "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/resources%2Fweb.png?alt=media&token=ae9f2987-3cc1-4dbf-826b-aca39abd6795",
                link: "https://github.com/MalakSadek?tab=repositories&q=&type=&language=javascript",},
        ];

        if (langs === {} || !langs) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Bounce />
                </div>
            );
        } else {
            return Object.keys(langs).map((keyName, i) => (
                <div className={tilesClasses} key={i}>
                    <div
                        className="tiles-item reveal-from-bottom"
                        data-reveal-container=".tiles-wrap"
                        data-aos="fade-up"
                        data-aos-delay={parseInt(i * 150)}
                    >
                        <div className="tiles-item-inner">
                            <div className="team-item-header">
                                <div className="team-item-image mb-24 illustration-element-06">
                                    <a href={langs[keyName].link}>
                                        <Image
                                            src={langs[keyName].thumbnail}
                                            alt="Team member 01"
                                            width={100}
                                            height={100}
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ));
        }
    }

    function Hobbies() {
        const hobs = [
            {
                "link" : "https://malaksadek.wordpress.com/2021/03/25/personal-designs-drawings/",
                "name" : "Drawing",
                "thumbnail" : "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/hobbies%2Fdrawing.png?alt=media&token=c4aeb857-97cb-4e9c-8280-e514ff72aa83"
            },
            {
                "link" : "https://steamcommunity.com/id/malaksadek/",
                "name" : "Video Games",
                "thumbnail" : "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/hobbies%2Fgaming.png?alt=media&token=675f3694-8c90-4935-b10c-84866b4e0437"
            },
            {
                "link" : "https://letterboxd.com/malouka/",
                "name" : "Movies",
                "thumbnail" : "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/hobbies%2Fmovies.jpg?alt=media&token=3b38c667-66b0-4341-9bae-0cf220ad68ae"
            },
            {
                "link" : "https://www.goodreads.com/user/show/22353252-malak-sadek",
                "name" : "Reading",
                "thumbnail" : "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/hobbies%2Freading.png?alt=media&token=e2c28b77-419a-454c-8f0c-a06002de52aa"
            },
            {
                "link" : "http://www.facebook.com/snippets97",
                "name" : "Writing",
                "thumbnail" : "https://firebasestorage.googleapis.com/v0/b/malak-portfolio.appspot.com/o/hobbies%2Fwriting.JPG?alt=media&token=370de3c5-1ec5-4ac3-a0c4-d21f5f960b72"
            },
        ];

        if (hobs === {} || !hobs) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Bounce />
                </div>
            );
        } else {
            return Object.keys(hobs).map((keyName, i) => (
                <div className={tilesClasses} key={i}>
                    <div
                        className="tiles-item reveal-from-bottom"
                        data-reveal-container=".tiles-wrap"
                        data-aos="fade-up"
                        data-aos-delay={parseInt(i * 150)}
                    >
                        <div className="tiles-item-inner">
                            <div className="team-item-header">
                                <div className="team-item-image mb-24 illustration-element-06">
                                    <a href={hobs[keyName].link}>
                                        <Image
                                            src={hobs[keyName].thumbnail}
                                            alt="Team member 01"
                                            width={100}
                                            height={100}
                                        />
                                    </a>
                                </div>
                                <div className="team-item-content">
                                    <h4 className="team-item-name mt-0 mb-4">
                                        {hobs[keyName].name}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ));
        }
    }

    function List() {
        if (projects === {} || !projects) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Bounce />
                </div>
            );
        } else {
            return Object.keys(projects).map((keyName, i) => (
                // <a onClick={() => goTo("/ProjectDetails", projects[keyName])}>
                <a href={projects[keyName].link} target="_blank">
                    <div className={tilesClasses} key={i}>
                        <div
                            className="tiles-item reveal-from-bottom"
                            data-reveal-container=".tiles-wrap"
                            data-aos="fade-up"
                            data-aos-delay={parseInt(i * 150)}
                        >
                            <div className="tiles-item-inner">
                                <div className="team-item-header">
                                    <div className="team-item-image mb-24 illustration-element-06">

                                        <Image
                                            src={projects[keyName].thumbnail}
                                            alt="Team member 01"
                                            width={200}
                                            height={200}
                                        />

                                    </div>
                                </div>
                                <div className="team-item-content">
                                    <h5 className="team-item-name mt-0 mb-4">
                                        {projects[keyName].name}
                                    </h5>

                                    {/* <div className="team-item-role text-xs fw-500 mb-8">
                  IOS/Android
                </div>
                <p className="m-0 text-sm">{projects[keyName].description1}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            ));
        }
    }

    function ListPubs() {
        if (pubs === {} || !pubs) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Bounce />
                </div>
            );
        } else {
            return Object.keys(pubs).map((keyName, i) => (
                <a href={pubs[keyName].link} target="_blank">
                    <div className={tilesClasses} key={i}>
                        <div
                            className="tiles-item reveal-from-bottom"
                            data-reveal-container=".tiles-wrap"
                            data-aos="fade-up"
                            data-aos-delay={parseInt(i * 150)}
                        >
                            <div className="tiles-item-inner">
                                <div className="team-item-header">
                                    <div className="team-item-image mb-24 illustration-element-06">
                                        <Image
                                            src={pubs[keyName].thumbnail}
                                            alt="Team member 01"
                                            width={200}
                                            height={200}
                                        />
                                    </div>
                                </div>
                                <div className="team-item-content">
                                    <h4 className="team-item-name mt-0 mb-4">
                                        {pubs[keyName].name}
                                    </h4>
                                    <h5>{pubs[keyName].venue}</h5>
                                    {pubs[keyName].talkorpaper}
                                    {/* <div className="team-item-role text-xs fw-500 mb-8">
                  IOS/Android
                </div>
                <p className="m-0 text-sm">{projects[keyName].description1}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            ));
        }
    }

    function PhoneList() {
        if (projects === {} || !projects) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Bounce />
                </div>
            );
        } else {
            return Object.keys(projects).map((keyName, i) => (
                <a href={projects[keyName].link} target="_blank">
                    <div className={tilesClasses} key={i}>
                        <div
                            className="tiles-item reveal-from-bottom"
                            data-reveal-container=".tiles-wrap"
                            data-aos="fade-up"
                            data-aos-delay={parseInt(i * 150)}
                        >
                            <div className="tiles-item-inner">
                                <div className="team-item-header">
                                    <div>
                                        <Image
                                            src={projects[keyName].thumbnail}
                                            alt="Team member 01"
                                            width={200}
                                            height={200}
                                        />
                                    </div>
                                </div>
                                <div className="team-item-content">
                                    <h5 className="team-item-name mt-0 mb-4">
                                        {projects[keyName].name}
                                    </h5>
                                    {/* <div className="team-item-role text-xs fw-500 mb-8">
                  IOS/Android
                </div>
                <p className="m-0 text-sm">{projects[keyName].description1}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            ));
        }
    }

    function PhoneListPubs() {
        if (pubs === {} || !pubs) {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Bounce />
                </div>
            );
        } else {
            return Object.keys(pubs).map((keyName, i) => (
                <a href={pubs[keyName].link} target="_blank">
                    <div className={tilesClasses} key={i}>
                        <div
                            className="tiles-item reveal-from-bottom"
                            data-reveal-container=".tiles-wrap"
                            data-aos="fade-up"
                            data-aos-delay={parseInt(i * 150)}
                        >
                            <div className="tiles-item-inner">
                                <div className="team-item-header">
                                    <div>
                                        <Image
                                            src={pubs[keyName].thumbnail}
                                            alt="Team member 01"
                                            width={200}
                                            height={200}
                                        />
                                    </div>
                                </div>
                                <div className="team-item-content">
                                    <h4 className="team-item-name mt-0 mb-4">
                                        {pubs[keyName].name}
                                    </h4>
                                    <h5>{pubs[keyName].venue}</h5>
                                    {pubs[keyName].talkorpaper}
                                    {/* <div className="team-item-role text-xs fw-500 mb-8">
                  IOS/Android
                </div>
                <p className="m-0 text-sm">{projects[keyName].description1}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            ));
        }
    }

    if (window.innerWidth < 1024) {
        return (
            <div style={{ width: "90%", marginTop: "20%" }}>
                <div style={{ paddingLeft: "10%" }}>
                    <div className="title-box-2">
                        <h5 className="title-left">Articles, Presentations, & Talks</h5>
                    </div>
                    <PhoneListPubs></PhoneListPubs>
                    <div className="title-box-2">
                        <h5 className="title-left">Projects</h5>
                    </div>
                    <PhoneList></PhoneList>
                    <div className="title-box-2">
                        <h5 className="title-left-skills">Programming Languages</h5>
                        <div className="row" height="100px"></div>
                        <h6>Click on a language to see the projects I've done using it</h6>
                    </div>
                    <div className={innerClasses}>
                        {
                        }
                        <div className={tilesClasses}>
                            <Languages></Languages>
                        </div>
                    </div>
                    <div className="title-box-2">
                        <h5 className="title-left">Hobbies & Personal Projects</h5>
                        <div className="row" height="100px"></div>
                    </div>
                    <div className={innerClasses}>
                        {
                        }
                        <div className={tilesClasses}>
                            <Hobbies></Hobbies>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ width: "90%" }}>
                <section {...props} className={outerClasses} id="work">
                    <div className="container">
                        <div style={{ paddingLeft: "10%" }}>
                            <div className="title-box-2">
                                <h5 className="title-left-skills">Articles, Presentations, & Talks</h5>
                            </div>
                            <div className={innerClasses}>
                                {/* <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-bottom"
            data-aos="fade-up"
          /> */}
                                <div className={tilesClasses}>
                                    <ListPubs></ListPubs>
                                </div>
                            </div>


                            <div className="title-box-2">
                                <h5 className="title-left">Projects</h5>
                            </div>
                            <div className={innerClasses}>
                                {/* <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-bottom"
            data-aos="fade-up"
          /> */}
                                <div className={tilesClasses}>
                                    <List></List>
                                </div>
                            </div>
                                <div className="title-box-2">
                                    <h5 className="title-left-skills">Programming Languages</h5>
                                    <div className="row" height="100px"></div>
                                    <h6>Click on a language to see the projects I've done using it</h6>
                                </div>
                                <div className={innerClasses}>
                                    {
                                    }
                                    <div className={tilesClasses}>
                                        <Languages></Languages>
                                    </div>
                                </div>

                            <div className="title-box-2">
                                <h5 className="title-left">Hobbies & Personal Projects</h5>
                                <div className="row" height="100px"></div>
                            </div>
                            <div className={innerClasses}>
                                {
                                }
                                <div className={tilesClasses}>
                                    <Hobbies></Hobbies>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
