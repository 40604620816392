import React from "react";
import myImage from "../img/myImage.png";
import classNames from "classnames";
import SectionHeader from "./SectionHeader";
import { storage } from "../config/firebaseConfig";
import Bounce from "react-activity/lib/Bounce";
import Image from "./Image";

class Publications extends React.Component {
  constructor() {
    super();
    this.state = {
      publications: [
          {
              id: "publication-one",
              content:
                  "Sadek, M., Calvo, R.A., Mougenot, C. (2023). Co-designing conversational agents: A comprehensive review and recommendations for best practices. <em>Design Studies</em>, 89." +
                  "<a href='https://doi.org/10.1016/j.destud.2023.101230'>https://doi.org/10.1016/j.destud.2023.101230</a>",
          },
        {
          id: "publication-two",
          content:
              "Sadek, M., Calvo, R.A., Mougenot, C. (2023). Designing value‐sensitive AI: a critical review and recommendations for socio‐technical design processes. <em>AI Ethics</em>." +
              "<a href='https://doi.org/10.1007/s43681-023-00373-7'>https://doi.org/10.1007/s43681-023-00373-7</a>",
        },
          {
              id: "publication-three",
              content:
                  "Sadek, M., Calvo, R.A., Mougenot, C. (2023).  Trends, Challenges and Processes in Conversational Agent Design: Exploring Practitioners’ Views through Semi-Structured Interviews. " +
                  "<em>In Proceedings of the 5th International Conference on Conversational User Interfaces (CUI’23)</em>. Association for Computing Machinery, New York, NY, USA, Article 13, 1-10. " +
                  "<a href='https://doi.org/10.1145/3571884.3597143'>https://doi.org/10.1145/3571884.3597143</a>",
          },
        {
          id: "publication-four",
          content:
              "Sadek, M., Calvo, R.A., Mougenot, C. (2023). Why codesigning AI is different and difficult. <em>ACM Interactions</em>." +
              "<a href='https://interactions.acm.org/blog/view/why-codesigning-ai-is-different-and-difficult'>https://interactions.acm.org/blog/view/why-codesigning-ai-is-different-and-difficult</a>",
        },
        {
          id: "publication-five",
          content:
              "Kallina, E. & Sadek, M. (2023). Who Defines AI’s Future? The Role of Harmful AI Narratives. <em>AI & Equality Community Publication.</em>" +
              "<a href='https://rb.gy/szlkke'>https://rb.gy/szlkke</a>",
        },
        {
          id: "publication-six",
          content:
              "Sadek, M., Kallina, E., Bohné, T., Mougenot, C., Calvo, R.A., Cave, S. (2024). Challenges of responsible AI in practice: scoping review and recommended actions. <em>AI \\& Society.</em> " +
              "<a href='https://doi.org/10.1007/s00146-024-01880-9'>https://doi.org/10.1007/s00146-024-01880-9</a>",
        },
        {
          id: "publication-seven",
          content:
              "Sadek, M., Constantinides, M., Quercia, D. and Mougenot, C. (2024). Guidelines for Integrating Value Sensitive Design in Responsible AI Toolkits. <em>In Proceedings of the 2024 CHI Conference on Human Factors in Computing Systems (CHI’24).</em> Association for Computing Machinery, New York, NY, USA. " +
              "<a href='https://doi.org/10.1145/3613904.3642810'>https://doi.org/10.1145/3613904.3642810</a>",
        },
        {
          id: "publication-eight",
          content:
              "For a full list of academic publications, please refer to my <a href='https://scholar.google.com/citations?hl=en&user=-0Sh_uAAAAAJ'>Google Scholar</a> profile." +
              "I also regularly post on <a href='https://medium.com/@malaksadekIC'>Medium</a> and <a href='https://scholar.google.com/citations?hl=en&user=-0Sh_uAAAAAJ'>Linkedin</a>",
        },

        ],
    };
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const tilesClasses = classNames("tiles-wrap", props.pushLeft && "push-left");

    const outerClasses = classNames(
      "features-tiles section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );


    if (window.innerWidth < 1024) {
      return (
        <div style={{ width: "90%" }}>
          <div style={{ paddingLeft: "10%" }}>
            <section id="publications">
              <div>
                <div>

                  <div className="about-me pt-4 pt-md-0">
                  <div className="title-box-2">
                    <h5 className="title-left">Selected Publications</h5>
                  </div>
                    <p className="about-text"
                       key="publication-eight"
                    >
                      For a full list of academic publications, please refer to my <a href='https://scholar.google.com/citations?hl=en&user=-0Sh_uAAAAAJ'>Google Scholar</a> profile. <br/>
                      I also regularly post on <a href='https://medium.com/@malaksadekIC'>Medium</a> and <a href='https://scholar.google.com/citations?hl=en&user=-0Sh_uAAAAAJ'>Linkedin</a>
                    </p>
                    <p className="about-text"
                       key="publication-seven"
                    >
                      Sadek, M., Constantinides, M., Quercia, D. and Mougenot, C. (2024). Guidelines for Integrating Value Sensitive Design in Responsible AI Toolkits. <em>In Proceedings of the 2024 CHI Conference on Human Factors in Computing Systems (CHI’24).</em> Association for Computing Machinery, New York, NY, USA. <br/>
                      <a href='https://doi.org/10.1145/3613904.3642810'>https://doi.org/10.1145/3613904.3642810</a>
                    </p>
                    <p className="about-text"
                       key="publication-six"
                    >
                       Sadek, M., Kallina, E., Bohné, T., Mougenot, C., Calvo, R.A., Cave, S. (2024). Challenges of responsible AI in practice: scoping review and recommended actions. <em>AI & Society.</em> <br/>
                      <a href='https://doi.org/10.1007/s00146-024-01880-9'>https://doi.org/10.1007/s00146-024-01880-9</a>
                    </p>
                    <p className="about-text"
                       key="publication-one"
                       >
                      Sadek, M., Calvo, R.A., Mougenot, C. (2023). Co-designing conversational agents: A comprehensive review and recommendations for best practices. <em>Design Studies</em>, 89. <br/>
                      <a href='https://doi.org/10.1016/j.destud.2023.101230'>https://doi.org/10.1016/j.destud.2023.101230</a>
                    </p>

                    <p className="about-text"
                       key="publication-two"
                    >
                      Sadek, M., Calvo, R.A., Mougenot, C. (2023). Designing value‐sensitive AI: a critical review and recommendations for socio‐technical design processes. <em>AI Ethics</em>. <br/>
                      <a href='https://doi.org/10.1007/s43681-023-00373-7'>https://doi.org/10.1007/s43681-023-00373-7</a>
                    </p>

                    <p className="about-text"
                       key="publication-three"
                    >
                      Sadek, M., Calvo, R.A., Mougenot, C. (2023).  Trends, Challenges and Processes in Conversational Agent Design: Exploring Practitioners’ Views through Semi-Structured Interviews. <em>In Proceedings of the 5th International Conference on Conversational User Interfaces (CUI’23)</em>. Association for Computing Machinery, New York, NY, USA, Article 13, 1-10. <br/>
                      <a href='https://doi.org/10.1145/3571884.3597143'>https://doi.org/10.1145/3571884.3597143</a>
                    </p>
                    <p className="about-text"
                       key="publication-four"
                    >
                      Sadek, M., Calvo, R.A., Mougenot, C. (2023). Why codesigning AI is different and difficult. <em>ACM Interactions</em>. <br/>
                      <a href='https://interactions.acm.org/blog/view/why-codesigning-ai-is-different-and-difficult'>https://interactions.acm.org/blog/view/why-codesigning-ai-is-different-and-difficult</a>
                    </p>
                    <p className="about-text"
                       key="publication-five"
                    >
                      Kallina, E. & Sadek, M. (2023). Who Defines AI’s Future? The Role of Harmful AI Narratives. <em>AI & Equality Community Publication.</em> <br/>
                      <a href='https://rb.gy/szlkke'>https://rb.gy/szlkke</a>
                    </p>

                  </div>

                </div>
              </div>
            </section>
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: "90%" }}>
          <section {...props} className={outerClasses}>
            <div className="container">
              <div style={{ paddingLeft: "10%" }}>
                <div className={innerClasses}>
                  <div
                    className="container-size reveal-from-bottom"
                    data-reveal-delay="300"
                    data-reveal-offset="0"
                    data-aos="fade-up"
                    data-aos-offset="0"
                    data-aos-delay="300"
                    style={placeholderStyle}
                  >
                    <div className="illustration-element-02">
                      <section id="publications" className="about-mf sect-pt4 route">
                        <div className="container">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="box-shadow-full">
                                <div className="about-row">

                                  <div className="about-me pt-4 pt-md-0">
                                  <div className="title-box-2">
                                    <h5 className="title-left">Selected Publications</h5>
                                  </div>
                                    <p className="about-text"
                                       key="publication-eight"
                                    >
                                      For a full list of academic publications, please refer to my <a href='https://scholar.google.com/citations?hl=en&user=-0Sh_uAAAAAJ'>Google Scholar</a> profile. <br/>
                                      I also regularly post on <a href='https://medium.com/@malaksadekIC'>Medium</a> and <a href='https://scholar.google.com/citations?hl=en&user=-0Sh_uAAAAAJ'>Linkedin</a>
                                    </p>
                                    <p className="about-text"
                                       key="publication-seven"
                                    >
                                      Sadek, M., Constantinides, M., Quercia, D. and Mougenot, C. (2024). Guidelines for Integrating Value Sensitive Design in Responsible AI Toolkits. <em>In Proceedings of the 2024 CHI Conference on Human Factors in Computing Systems (CHI’24).</em> Association for Computing Machinery, New York, NY, USA. <br/>
                                      <a href='https://doi.org/10.1145/3613904.3642810'>https://doi.org/10.1145/3613904.3642810</a>
                                    </p>
                                    <p className="about-text"
                                       key="publication-six"
                                    >
                                      Sadek, M., Kallina, E., Bohné, T., Mougenot, C., Calvo, R.A., Cave, S. (2024). Challenges of responsible AI in practice: scoping review and recommended actions. <em>AI & Society.</em> <br/>
                                      <a href='https://doi.org/10.1007/s00146-024-01880-9'>https://doi.org/10.1007/s00146-024-01880-9</a>
                                    </p>
                                    <p className="about-text"
                                       key="publication-one"
                                    >
                                      Sadek, M., Calvo, R.A., Mougenot, C. (2023). Co-designing conversational agents: A comprehensive review and recommendations for best practices. <em>Design Studies</em>, 89. <br/>
                                      <a href='https://doi.org/10.1016/j.destud.2023.101230'>https://doi.org/10.1016/j.destud.2023.101230</a>
                                    </p>

                                    <p className="about-text"
                                       key="publication-two"
                                    >
                                      Sadek, M., Calvo, R.A., Mougenot, C. (2023). Designing value‐sensitive AI: a critical review and recommendations for socio‐technical design processes. <em>AI Ethics</em>. <br/>
                                      <a href='https://doi.org/10.1007/s43681-023-00373-7'>https://doi.org/10.1007/s43681-023-00373-7</a>
                                    </p>

                                    <p className="about-text"
                                       key="publication-three"
                                    >
                                      Sadek, M., Calvo, R.A., Mougenot, C. (2023).  Trends, Challenges and Processes in Conversational Agent Design: Exploring Practitioners’ Views through Semi-Structured Interviews. <em>In Proceedings of the 5th International Conference on Conversational User Interfaces (CUI’23)</em>. Association for Computing Machinery, New York, NY, USA, Article 13, 1-10. <br/>
                                      <a href='https://doi.org/10.1145/3571884.3597143'>https://doi.org/10.1145/3571884.3597143</a>
                                    </p>
                                    <p className="about-text"
                                       key="publication-four"
                                    >
                                      Sadek, M., Calvo, R.A., Mougenot, C. (2023). Why codesigning AI is different and difficult. <em>ACM Interactions</em>. <br/>
                                      <a href='https://interactions.acm.org/blog/view/why-codesigning-ai-is-different-and-difficult'>https://interactions.acm.org/blog/view/why-codesigning-ai-is-different-and-difficult</a>
                                    </p>
                                    <p className="about-text"
                                       key="publication-five"
                                    >
                                      Kallina, E. & Sadek, M. (2023). Who Defines AI’s Future? The Role of Harmful AI Narratives. <em>AI & Equality Community Publication.</em> <br/>
                                      <a href='https://rb.gy/szlkke'>https://rb.gy/szlkke</a>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
}
const placeholderStyle = {
  paddingBottom: "10px",
};

export default Publications;
