import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import "firebase/compat/functions";
require("firebase/compat/functions");

const firebaseConfig = {
  apiKey: "AIzaSyACu9walnTmpR34o8MmPQoVvg60a_UHz6w",
  authDomain: "malak-portfolio.firebaseapp.com",
  databaseURL: "https://malak-portfolio.firebaseio.com",
  projectId: "malak-portfolio",
  storageBucket: "malak-portfolio.appspot.com",
  messagingSenderId: "1078408483932",
  appId: "1:1078408483932:web:2bc9e638b0a62ad1a80299",
  measurementId: "G-57XSRSSGRG",
};

firebase.initializeApp(firebaseConfig);

// Initialize Cloud Functions through Firebase

export const functions = firebase.functions();

export const f = firebase;
export const database = firebase.database();
export const auth = firebase.auth();
export const storage = firebase.storage();
