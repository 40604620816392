import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Bounce from "react-activity/lib/Bounce";
import Navbar from "./navbar";
import Navbar2 from "./navbar2";
import FeaturesSplit from "./FeaturesSplit";
import BackToTop from "./back-top";
import classNames from "classnames";
import Image from "./Image";

const ProjectDetails = (props) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
  } = props;

  const innerClasses = classNames(
    "team-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");
  function Images() {
    return Object.values(location.state.project.imgs).map((img, i) => (
      <div className={tilesClasses} key={i}>
        <div
          className="tiles-item reveal-from-bottom"
          data-reveal-container=".tiles-wrap"
          data-aos="fade-up"
          data-aos-delay={parseInt(i * 150)}
        >
          <div className="tiles-item-inner">
            <div className="team-item-header">
              <div className="mb-24 illustration-element-06">
                <Image src={img} alt="Team member 01" width={250} />
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }
  function PhoneImages() {
    return Object.values(location.state.project.imgs).map((img, i) => (
      <div className={tilesClasses} key={i}>
        <div
          className="tiles-item reveal-from-bottom"
          data-reveal-container=".tiles-wrap"
          data-aos="fade-up"
          data-aos-delay={parseInt(i * 150)}
        >
          <div className="tiles-item-inner">
            <div className="team-item-header">
              <div>
                <Image src={img} alt="Team member 01" width={250} />
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  }
  function Links() {
    return Object.keys(location.state.project.links).map((link, i) => (
      <div>
        {link}
        {"   "}
        <a href={link} target="_blank">
          {location.state.project.links[link]}
        </a>
      </div>
    ));
  }
  // function About() {
  //   return Object.values(location.state.project.descriptions).map((img, i) => (
  //     <div className="tiles-item-inner">
  //       <div className="team-item-header">
  //         <img src={img} style={{ blockSize: 200 }} />
  //       </div>
  //     </div>
  //   ));
  // }
  function Descriptions() {
    return Object.values(location.state.project.description2).map(
      (paragraph, i) => (
        <>
          <div style={{ marginBottom: "5%" }}>{paragraph}</div>
        </>
      )
    );
  }
  if (window.innerWidth < 1024) {
    return (
      <>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "25%",
            }}
          >
            <Bounce />
          </div>
        ) : (
          <>
            {/* <Images />
          <span>{location.state.project.description1}</span> */}
            <React.Fragment>
              <main className="site-content">
                <Navbar2 />
                <div style={{ height: 150 }} />
                <div
                  style={{
                    width: "90%",
                    marginLeft: "5%",
                    marginTop: "5%",
                    justifyContent: "center",
                    marginBottom: "5%",
                  }}
                >
                  <h5 className="title-left" style={{ marginBottom: "5%" }}>
                    {Object.values(location.state.project.name)}
                  </h5>
                  {/* <About/> */}
                  {location.state.project.description2 ? (
                    <div>
                      {Object.values(location.state.project.description1)}
                    </div>
                  ) : null}

                  <div className={innerClasses}>
                    {/* <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-bottom"
            data-aos="fade-up"
          /> */}
                    <div className={tilesClasses}>
                      <PhoneImages />
                    </div>
                  </div>
                  {location.state.project.description2 ? (
                    // <div style={{ marginBottom: "5%" }}>
                    //   {Object.values(location.state.project.description2)}
                    // </div>
                    <Descriptions />
                  ) : null}

                  <Links />
                  <BackToTop />
                </div>
              </main>
            </React.Fragment>
          </>
        )}
      </>
    );
  }
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "25%",
          }}
        >
          <Bounce />
        </div>
      ) : (
        <>
          {/* <Images />
          <span>{location.state.project.description1}</span> */}
          <React.Fragment>
            <main className="site-content">
              <Navbar2 />
              <div style={{ height: 150 }} />
              <div
                style={{
                  width: "90%",
                  marginLeft: "5%",
                  marginTop: "5%",
                  justifyContent: "center",
                  marginBottom: "5%",
                }}
              >
                <h5 className="title-left" style={{ marginBottom: "5%" }}>
                  {Object.values(location.state.project.name)}
                </h5>
                {/* <About/> */}
                {location.state.project.description2 ? (
                  <div>
                    {Object.values(location.state.project.description1)}
                  </div>
                ) : null}

                <div className={innerClasses}>
                  {/* <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-bottom"
            data-aos="fade-up"
          /> */}
                  <div className={tilesClasses}>
                    <Images />
                  </div>
                </div>
                {location.state.project.description2 ? (
                  <div style={{ marginBottom: "5%" }}>
                    <Descriptions />
                  </div>
                ) : null}

                <Links />
                <BackToTop />
              </div>
            </main>
          </React.Fragment>
        </>
      )}
    </>
  );
};

export default ProjectDetails;
