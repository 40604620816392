import React from "react";
import $ from "jquery";

// import logo1 from "../img/male1.png";
import logo1 from "../img/male.png";
import logo2 from "../assets/images/team-member-01.jpg";
import Image from "./Image";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

class Navbar2 extends React.Component {
  constructor() {
    super();
    this.state = {
      logo: logo1,
    };
  }

  componentDidMount() {
    const nav = $("nav");

    $("#mainNav").addClass("navbar-reduce");

    // document.querySelector(".navbar-expand-md").classList.add("navbar-reduce");
    // document
    //   .querySelector(".navbar-expand-md")
    //   .classList.remove("navbar-trans");

    this.setState({ logo: logo2 });

    $('a.js-scroll[href*="#"]:not([href="#"])').on("scroll", function () {
      if (
        window.location.pathname.replace(/^\//, "") ===
          this.pathname.replace(/^\//, "") &&
        window.location.hostname === this.hostname
      ) {
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
      }
    });
  }

  render() {
    return (
      <nav className="navbar navbar-b fixed-top" id="mainNav">
        <div className="container">
          <div className="navbar-brand js-scroll">
            {this.state.logo === logo2 ? (
              <img
                src={this.state.logo}
                alt="logo"
                style={{ maxWidth: "100px", borderRadius: "50%" }}
              />
            ) : null}
          </div>

          <div id="navbarDefault">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link js-scroll active" to="/">
                  Home
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
const nav = $("nav");
let navHeight = nav.outerHeight();

export default Navbar2;
