import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../utils/SectionProps";
import SectionHeader from "./SectionHeader";
import Image from "./Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "team section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "team-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const sectionHeader = {
      title: "Our story - Lorem ipsum is placeholder text.",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-bottom"
            />

            <div className={splitClasses}>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-left"
                    data-reveal-container=".split-item"
                  >
                    Freedom from designers
                  </h3>
                  <p
                    className="m-0 reveal-from-left"
                    data-reveal-container=".split-item"
                    data-reveal-delay="100"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua — Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-02 reveal-from-right",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    src={require("../assets/images/features-split-image.svg")}
                    alt="Features split 01"
                    width={528}
                    height={396}
                  />
                  <div style={imgStyle}>
                    <Image
                      src={require("../assets/images/features-split-top-01.png")}
                      alt="Features split top 01"
                      width={654}
                      height={522}
                    />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-right"
                    data-reveal-container=".split-item"
                  >
                    Freedom from designers
                  </h3>
                  <p
                    className="m-0 reveal-from-right"
                    data-reveal-container=".split-item"
                    data-reveal-delay="100"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua — Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-03 reveal-from-left",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    src={require("../assets/images/features-split-image.svg")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                  <div style={imgStyle}>
                    <Image
                      src={require("../assets/images/features-split-top-02.png")}
                      alt="Features split top 02"
                      width={654}
                      height={521}
                    />
                  </div>
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3
                    className="mt-0 mb-16 reveal-from-left"
                    data-reveal-container=".split-item"
                  >
                    Freedom from designers
                  </h3>
                  <p
                    className="m-0 reveal-from-left"
                    data-reveal-container=".split-item"
                    data-reveal-delay="100"
                  >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua — Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                </div>
                <div
                  className={classNames(
                    "split-item-image center-content-mobile illustration-element-04 reveal-from-right",
                    imageFill && "split-item-image-fill"
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image
                    src={require("../assets/images/features-split-image.svg")}
                    alt="Features split 03"
                    width={528}
                    height={396}
                  />
                  <div style={imgStyle}>
                    <Image
                      src={require("../assets/images/features-split-top-03.png")}
                      alt="Features split top 03"
                      width={654}
                      height={436}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const imgStyle = {
  position: "absolute",
  width: "118.18%",
  maxWidth: "118.18%",
  top: "-7.07%",
  left: "-9.09%",
};

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
