import React from "react";
import myImage from "../img/myImage.png";
import classNames from "classnames";
import SectionHeader from "./SectionHeader";
import { storage } from "../config/firebaseConfig";
import Bounce from "react-activity/lib/Bounce";
import Image from "./Image";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        {  id: "HCI",
          title:"Research, Design, and Product Management",
          content0: "",
          subtitle1: "Tools",
          content1: "Tableau, SPSS, NVivo, PowerBI, Jira, Confluence, Notion, Trello, Figma, Adobe XD, Google Analytics, Mailchimp, Axure RP, Balsamiq, Illustrator, Photoshop",
          subtitle2: "",
          content2: "",
          subtitle3: "Frameworks, Methodologies & Skills",
          content3: "Agile Development, Scrum, Design Research Methodology, Quantitative & Qualitative User Research Methods, User-Centered Design, SEO, Digital Marketing, Value-Sensitive Design, Collaborative Design, Rapid Prototyping, Design Lifecycle",
          subtitle4: "",
          content4: "",
          subtitle5: "",
          content5: "",
        },
        {  id: "CS",
          title:"Computer Science",
          content0: "",
            subtitle1: "Conversational Agent Development",
            content1: "RASA, Conversation-Driven Development, VoiceFlow",
          subtitle2: "Mobile Application & Web Development",
          content2: "iOS, Android, CoreML, Tensorflow Lite, D3.js, Node.js, React Native, Bootstrap, Django, Postman, Qt",
          subtitle3: "Database Management Systems",
          content3: "SQL, Relational Databases, NoSQL, PHPMyAdmin, MySQL, Google Firebase",
          subtitle4: "Data Science",
          content4: "Advanced Statistical Data Analysis, NumPy",
          subtitle5: "Software Engineering",
          content5: "Visual Paradigm, Object-Oriented Programming, Software Requirements Specification, Software Design Documentation, Version Control",
        },
      ],
      about_me: [
          {
              id: "first-p-about",
              content:
                  "My name is Malak (which means angel in Arabic) and I was born and raised in Cairo, Egypt. I'm currently a Design Engineering postgraduate researcher at Imperial College London. I got my Bachelor’s degree in Computer Engineering with a minor in Digital Media from the American University in Cairo in 2019 and then moved to Scotland to get a Master’s degree in Human Computer Interaction from the University of St Andrews.",
          },
          {
              id: "second-p-about",
              content:
                  "In my spare time, I enjoy learning about animals and interacting with them, playing squash, watching Formula 1, playing video games, and reading.",
          },
          {
              id: "third-p-about",
              content:
                  "I put together this website as a portfolio for all the work I've done. I’m currently seeking full-time employment – so head over to the contact page if that’s what you’re here for!",
          },
      ],
    };
  }
   downloadCV() {
    storage
      .ref("cv/cv.pdf")
      .getDownloadURL()
      .then(function (url) {
        // `url` is the download URL for 'images/stars.jpg'

        // This can be downloaded directly:
        var xhr = new XMLHttpRequest();
        xhr.responseType = "blob";
        xhr.onload = function (event) {
          var blob = xhr.response;
        };
        xhr.open("GET", url);
        xhr.send();
        // Or inserted into an <img> element:
        var img = document.getElementById("download");
        img.href = url;
        img.click();
      })
      .catch(function (error) {
        console.log(error);
        // Handle any errors
      });
  }


  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const tilesClasses = classNames("tiles-wrap", props.pushLeft && "push-left");

    const outerClasses = classNames(
      "features-tiles section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );


    if (window.innerWidth < 1024) {
      return (
        <div style={{ width: "90%" }}>
          <div style={{ paddingLeft: "10%" }}>
            <section id="about">
              <div>
                <div>
                  <div className="title-box-2">
                    <h5 className="title-left">About Me</h5>
                  </div>
                  {this.state.about_me.map((content) => {
                    return (
                      <p className="about-text" key={content.id}>
                        {content.content}

                      </p>
                    );
                  })}
                </div>
              </div>
                <div className="title-box-2">
                  <h5 className="title-left">Skills, Tools & Frameworks</h5>
                </div>
                {this.state.skills.map((content) => {
                  return (
                      <p
                          className="skills-text"
                          key={content.id}
                      >
                        <h4> {content.title}  </h4>
                        {content.content0}
                        <h6> {content.subtitle1} </h6>
                        {content.content1}
                        <h6> {content.subtitle2} </h6>
                        {content.content2}
                        <h6> {content.subtitle3} </h6>
                        {content.content3}
                        <h6> {content.subtitle4} </h6>
                        {content.content4}
                        <h6> {content.subtitle5} </h6>
                        {content.content5}
                        <h5 className="title-left-skills-div"></h5>

                      </p>
                  );
                })}
                <div className="about-sections">
                    <div className="about-me pt-4 pt-md-0">
                        <div className="title-box-2">
                            <h5 className="title-left">About Me</h5>
                        </div>
                        {this.state.about_me.map((content) => {
                            return (
                                <p
                                    className="about-text"
                                    key={content.id}
                                >
                                    {content.content}
                                </p>
                            );
                        })}
                    </div>
                    <a download id="download"
                       href="https://www.imperial.ac.uk/design-engineering/study/phd/malak/">
                        <button
                            type="submit"
                            className="button button-a button-big button-rouded"
                            ref="https://www.imperial.ac.uk/design-engineering/study/phd/malak/"
                        >
                            Learn About my PhD
                        </button>
                    </a>
                    <div><p></p></div>
                    <a   download id="download">
                        <button
                            type="submit"
                            className="button button-a button-big button-rouded"
                            onClick={() => this.downloadCV()}

                        >
                            View My CV
                        </button>
                    </a>
                    <div><p></p></div>
                    <a download id="download"
                       href="https://z7sdpb.axshare.com/#sc=1">
                        <button
                            type="submit"
                            className="button button-a button-big button-rouded"
                            ref="https://z7sdpb.axshare.com/#sc=1"
                        >
                            View My Certificates
                        </button>
                    </a>

                </div>
            </section>

          </div>
        </div>
      );
    } else {
      return (
        <div style={{ width: "90%" }}>
          <section {...props} className={outerClasses}>
            <div className="container">
              <div style={{ paddingLeft: "10%" }}>
                <div className={innerClasses}>
                  <div
                    className="container-size reveal-from-bottom"
                    data-reveal-delay="300"
                    data-reveal-offset="0"
                    data-aos="fade-up"
                    data-aos-offset="0"
                    data-aos-delay="300"
                    style={placeholderStyle}
                  >
                    <div className="illustration-element-02">
                      <section id="about" className="about-mf sect-pt4 route">
                        <div className="container">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="box-shadow-full">
                                <div className="about-row">
                                    <div className="about-sections">
                                    <div className="about-me pt-4 pt-md-0">
                                      <div className="title-box-2">
                                        <h5 className="title-left-skills">Skills, Tools & Frameworks</h5>
                                      </div>
                                      {this.state.skills.map((content) => {
                                        return (
                                            <p
                                                className="skills-text"
                                                key={content.id}
                                            >
                                              <h4> {content.title}  </h4>
                                              {content.content0}
                                              <h6> {content.subtitle1} </h6>
                                              {content.content1}
                                              <h6> {content.subtitle2} </h6>
                                              {content.content2}
                                              <h6> {content.subtitle3} </h6>
                                              {content.content3}
                                              <h6> {content.subtitle4} </h6>
                                              {content.content4}
                                              <h6> {content.subtitle5} </h6>
                                              {content.content5}
                                              <h5 className="title-left-skills-div"></h5>
                                            </p>
                                        );
                                      })}
                                    </div>
                                  </div>
                                  <div className="about-sections">
                                    <div className="about-me pt-4 pt-md-0">
                                      <div className="title-box-2">
                                        <h5 className="title-left">About Me</h5>
                                      </div>
                                      {this.state.about_me.map((content) => {
                                        return (
                                          <p
                                            className="about-text"
                                            key={content.id}
                                          >
                                            {content.content}
                                          </p>
                                        );
                                      })}
                                    </div>
                                      <a download id="download"
                                         href="https://www.imperial.ac.uk/design-engineering/study/phd/malak/">
                                          <button
                                              type="submit"
                                              className="button button-a button-big button-rouded"
                                              ref="https://www.imperial.ac.uk/design-engineering/study/phd/malak/"
                                          >
                                              Learn About my PhD
                                          </button>
                                      </a>
                                      <div><p></p></div>
                                     <a   download id="download">

                                         <button
                            type="submit"
                            className="button button-a button-big button-rouded"
                            onClick={() => this.downloadCV()}
                          
                          >
                            View My CV
                          </button>
                                     </a>

                                    <a download id="download"
                                    href="https://z7sdpb.axshare.com/#sc=1">
                                      <button
                                          type="submit"
                                          className="button button-a button-big button-rouded"
                                          ref="https://z7sdpb.axshare.com/#sc=1"
                                      >
                                        View My Certificates
                                      </button>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      );
    }
  }
}
const placeholderStyle = {
  paddingBottom: "60px",
};

export default About;
