import React from "react";
import About from "./components/about";
import BackToTop from "./components/back-top";
import Contact from "./components/contact";
import FeaturesSplit from "./components/FeaturesSplit";
import FeaturesTiles from "./components/FeaturesTiles";
import Intro from "./components/intro";
import Navbar from "./components/navbar";
import Portfolio from "./components/portfolio";
import Roadmap from "./components/Roadmap";
import Preloader from "./components/preloader";
import ScrollReveal from "./utils/ScrollReveal";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Team from "./components/Team";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ProjectDetails from "./components/ProjectDetails";
import "react-activity/lib/Bounce/Bounce.css";
import Navbar2 from "./components/navbar2";
import { database } from "./config/firebaseConfig";
import Bounce from "react-activity/lib/Bounce";
import "react-slideshow-image/dist/styles.css";
import Particles from "react-tsparticles";
import Publications from "./components/publications";

// Views
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;


class App extends React.Component {
  constructor() {
    super();
    this.state = {
      projects: null,
    };
  }

  componentDidMount() {
    database.ref("projects/").once("value", (snapshot) => {
      if (snapshot.exists()) {
        this.setState({ projects: snapshot.val() });
        document.body.classList.add("is-loaded");
        this.refs.scrollReveal.init();
        AOS.init({ duration: 600 });
      } else {
        alert("No projects available");
      }
    });
  }

  render() {
    function Loader() {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >


          <Bounce />
        </div>
      );
    }
    if (this.state.projects)
      return (
        <ScrollReveal
          ref="scrollReveal"
          children={() => (
            <Switch>
              <Route exact path="/">
                <React.Fragment>
                  <main className="site-content">
                    <Navbar />
                    <Intro />

                    <About />
                    <Roadmap topDivider id="experience" />
                    {/* <FeaturesTiles /> */}
                    {/* <Portfolio /> */}

                    <Publications />
                    <Team />

                    <Contact />
                    <BackToTop />
                    {/* <Preloader /> */}
                  </main>
                </React.Fragment>
              </Route>
              <Route path="/ProjectDetails">
                <ProjectDetails />
              </Route>
            </Switch>
          )}
        />
      );
    else {
      return <Loader />;
    }
  }
}

export default () => {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
};
