import React from "react";
import "./stars.scss";
import Typed from "react-typed";
import Image from "./Image";
import logo1 from "../assets/images/team-member-01.jpg";
import Particles from "react-tsparticles";

class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      logo: logo1,
      show: true,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 50) {
        this.setState({ show: false });
      } else {
        this.setState({ show: true });
      }
    });
  }

  render() {
    return (
      // <div id="home" className="intro route bg-image " style={{backgroundImage: "url("+bigImage+")"}}>
      <div id="home" className="intro route bg-image background">
        
        {/* <div id="stars" />
        <div id="stars2" />
        <div id="stars3" /> */}
        <Particles style={{position:"absolute"}}
                      params={{
                        particles: {
                          number: {
                            value: 110,
                            density: {
                              enable: true,
                              value_area: 700,
                            },
                          },
                          color: {
                            value: "#ffffff",
                          },
                          shape: {
                            type: "polygon",
                            stroke: {
                              width: 1,
                              color: "#000000",
                            },
                            polygon: {
                              nb_sides: 6,
                            },
                            image: {
                              src: "img/github.svg",
                              width: 100,
                              height: 100,
                            },
                          },
                          opacity: {
                            value: 1,
                            random: true,
                            anim: {
                              enable: true,
                              speed: 1,
                              opacity_min: 0.1,
                              sync: false,
                            },
                          },
                          size: {
                            value: 3,
                            random: true,
                            anim: {
                              enable: false,
                              speed: 4,
                              size_min: 0.3,
                              sync: false,
                            },
                          },
                          line_linked: {
                            enable: true,
                            distance: 150,
                            color: "#ffffff",
                            opacity: 0.5,
                            width: 1,
                          },
                          move: {
                            enable: true,
                            speed: 2,
                            direction: "none",
                            random: true,
                            straight: false,
                            out_mode: "bounce",
                            bounce: true,
                            attract: {
                              enable: false,
                              rotateX: 600,
                              rotateY: 600,
                            },
                          },
                        },
                        interactivity: {
                          detect_on: "canvas",
                          events: {
                            onhover: {
                              enable: true,
                              mode: "grab",
                            },
                            onclick: {
                              enable: true,
                              mode: "push",
                            },
                            resize: true,
                          },
                          modes: {
                          },
                        },
            retina_detect: true,
                        
                      }}
                    />

        <div className="intro-content display-table">
          <div className="table-cell">
            <div className="container">
              <div
                className="team-item-image mb-24 illustration-element-06"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {this.state.show ? (
                  <Image
                    src={this.state.logo}
                    alt="Team member 01"
                    width={200}
                  />
                ) : null}
              </div>
              {window.innerWidth < 1024 ? (
                <p className="intro-title mb-4">Hello, my name is Malak</p>
              ) : (
                <h5 className="intro-title mb-4">Hello, my name is Malak</h5>
              )}
              <p className="intro-subtitle">
                <span className="text-slider-items"></span>
                <strong className="text-slider">
                  <Typed
                    strings={[
                      "Design Engineering Doctoral Candidate","Mixed Method Researcher (User, Design, Product)","User Experience Designer", "Computer Scientist and Engineer",
                    ]}
                    typeSpeed={80}
                    backDelay={1100}
                    backSpeed={30}
                    loop
                  />
                </strong>
              </p>
              {/* <p className="pt-3">
                <a
                  className="btn btn-primary btn js-scroll px-4"
                  href="#work"
                  role="button"
                >
                  View My Work
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;
