import React from "react";
import classNames from "classnames";
import SectionHeader from "./SectionHeader";
import Image from "./Image";
import { SectionTilesProps } from "../utils/SectionProps";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-tiles section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "Duis aute irure dolor in velit esse-reprehenderit cillum dolore.",
      paragraph:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore aliqua.",
    };

    return (
      <div style={{ width: "90%" }}>
        <section {...props} className={outerClasses}>
          <div className="container">
            <div style={{ paddingLeft: "10%" }}>
              <div className={innerClasses}>
                {/* <SectionHeader
              data-aos="fade-up"
              data-aos-delay="150"
              data={sectionHeader}
              className="center-content reveal-from-bottom"
              data-reveal-delay="150"
            />
            <div
              className="container-sm reveal-from-bottom"
              data-reveal-delay="300"
              data-reveal-offset="0"
              data-aos="fade-up"
              data-aos-offset="0"
              data-aos-delay="300"
              style={placeholderStyle}
            >
              <div className="illustration-element-02">
                <Image
                  className="has-shadow"
                  src={require("../assets/features-placeholder.png")}
                  alt="Features placeholder"
                  width={896}
                  height={502}
                />
              </div>
            </div> */}
                <div className={tilesClasses}>
                  <div
                    className="tiles-item reveal-from-bottom"
                    data-reveal-container=".tiles-wrap"
                    data-aos="fade-up"
                  >
                    <div className="tiles-item-inner">
                      <div className="features-tiles-item-header">
                        <div className="features-tiles-item-image mb-16">
                          <Image
                            src={require("../assets/feature-tile-icon-01.svg")}
                            alt="Features tile icon 01"
                            width={56}
                            height={56}
                          />
                        </div>
                      </div>
                      <div className="features-tiles-item-content">
                        <h4 className="mt-0 mb-8">Online Courses</h4>
                        <p className="m-0 text-sm">
                          Luctus accumsan tortor posuere ac — tempus iaculis
                          urna id volutpat lacus laoreet non orci phasellus
                          egestas tellus rutrum tellus eu ultrices vitae.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tiles-item reveal-from-bottom"
                    data-reveal-container=".tiles-wrap"
                    data-reveal-delay="150"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <div className="tiles-item-inner">
                      <div className="features-tiles-item-header">
                        <div className="features-tiles-item-image mb-16">
                          <Image
                            src={require("../assets/feature-tile-icon-02.svg")}
                            alt="Features tile icon 02"
                            width={56}
                            height={56}
                          />
                        </div>
                      </div>
                      <div className="features-tiles-item-content">
                        <h4 className="mt-0 mb-8">Online Courses</h4>
                        <p className="m-0 text-sm">
                          Luctus accumsan tortor posuere ac — tempus iaculis
                          urna id volutpat lacus laoreet non orci phasellus
                          egestas tellus rutrum tellus eu ultrices vitae.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tiles-item reveal-from-bottom"
                    data-reveal-container=".tiles-wrap"
                    data-reveal-delay="300"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="tiles-item-inner">
                      <div className="features-tiles-item-header">
                        <div className="features-tiles-item-image mb-16">
                          <Image
                            src={require("../assets/feature-tile-icon-03.svg")}
                            alt="Features tile icon 03"
                            width={56}
                            height={56}
                          />
                        </div>
                      </div>
                      <div className="features-tiles-item-content">
                        <h4 className="mt-0 mb-8">Online Courses</h4>
                        <p className="m-0 text-sm">
                          Luctus accumsan tortor posuere ac — tempus iaculis
                          urna id volutpat lacus laoreet non orci phasellus
                          egestas tellus rutrum tellus eu ultrices vitae.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tiles-item reveal-from-bottom"
                    data-reveal-container=".tiles-wrap"
                    data-reveal-delay="450"
                    data-aos="fade-up"
                    data-aos-delay="450"
                  >
                    <div className="tiles-item-inner">
                      <div className="features-tiles-item-header">
                        <div className="features-tiles-item-image mb-16">
                          <Image
                            src={require("../assets/feature-tile-icon-04.svg")}
                            alt="Features tile icon 04"
                            width={56}
                            height={56}
                          />
                        </div>
                      </div>
                      <div className="features-tiles-item-content">
                        <h4 className="mt-0 mb-8">Online Courses</h4>
                        <p className="m-0 text-sm">
                          Luctus accumsan tortor posuere ac — tempus iaculis
                          urna id volutpat lacus laoreet non orci phasellus
                          egestas tellus rutrum tellus eu ultrices vitae.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tiles-item reveal-from-bottom"
                    data-reveal-container=".tiles-wrap"
                    data-reveal-delay="600"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="tiles-item-inner">
                      <div className="features-tiles-item-header">
                        <div className="features-tiles-item-image mb-16">
                          <Image
                            src={require("../assets/feature-tile-icon-05.svg")}
                            alt="Features tile icon 05"
                            width={56}
                            height={56}
                          />
                        </div>
                      </div>
                      <div className="features-tiles-item-content">
                        <h4 className="mt-0 mb-8">Online Courses</h4>
                        <p className="m-0 text-sm">
                          Luctus accumsan tortor posuere ac — tempus iaculis
                          urna id volutpat lacus laoreet non orci phasellus
                          egestas tellus rutrum tellus eu ultrices vitae.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tiles-item reveal-from-bottom"
                    data-reveal-container=".tiles-wrap"
                    data-reveal-delay="750"
                    data-aos="fade-up"
                    data-aos-delay="750"
                  >
                    <div className="tiles-item-inner">
                      <div className="features-tiles-item-header">
                        <div className="features-tiles-item-image mb-16">
                          <Image
                            src={require("../assets/feature-tile-icon-06.svg")}
                            alt="Features tile icon 06"
                            width={56}
                            height={56}
                          />
                        </div>
                      </div>
                      <div className="features-tiles-item-content">
                        <h4 className="mt-0 mb-8">Online Courses</h4>
                        <p className="m-0 text-sm">
                          Luctus accumsan tortor posuere ac — tempus iaculis
                          urna id volutpat lacus laoreet non orci phasellus
                          egestas tellus rutrum tellus eu ultrices vitae.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const placeholderStyle = {
  paddingBottom: "80px",
};
FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
